document.addEventListener('DOMContentLoaded', function () {
  const navbar = document.querySelector('#main-header');

  window.addEventListener('scroll', function () {
    if (window.scrollY > 50) {
      navbar.classList.add('scrolled');
      navbar.classList.remove('transparent');
    } else {
      navbar.classList.remove('scrolled');
      navbar.classList.add('transparent');
    }
  });

  // Smooth scroll for links
  const links = document.querySelectorAll('.col-navigation a');
  const mobileMenu = document.querySelector('#menu-mobile');
  const mobileLinks = document.querySelectorAll('#menu-mobile .row-links a');

  links.forEach(function (link) {
    link.addEventListener('click', function (e) {
      e.preventDefault();

      let targetId = this.getAttribute('href').substring(1);
      let targetElement = document.getElementById(targetId);

      if (targetElement) {
        let offset =
          targetElement.offsetTop -
          document.querySelector('#main-header').offsetHeight;
        window.scrollTo({
          top: offset,
          behavior: 'smooth',
        });
      }
    });
  });

  // Mobile
  mobileLinks.forEach(function (mobileLink) {
    mobileLink.addEventListener('click', function (e) {
      e.preventDefault();
      mobileMenu.classList.remove('visible');

      let mobiletargetId = this.getAttribute('href').substring(1);
      let mobiletargetElement = document.getElementById(mobiletargetId);

      if (mobiletargetElement) {
        let offset =
          mobiletargetElement.offsetTop -
          document.querySelector('#menu-mobile').offsetHeight + 850;

        window.scrollTo({
          top: offset,
          behavior: 'smooth',
        });
      }
    });
  });
});
